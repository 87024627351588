@import url('https://use.typekit.net/mjw0jrk.css');

.App {
  text-align: center;
}

.window {
  display: flex;
  flex-flow: column;
  height: 100vh;
  width: 100%;
}


.flex {
  display: flex;
}


.tag-box {
  margin-top: 50px;
  border: none;
  border-bottom: 2px solid black;
  width: 100%;
}

.description-box {
  border: none;
  border-bottom: 2px solid black;
  width: 100%;
}


.image-column {
  display: inline-block;
  width: 18%;
  margin-left: 1.666%;
  vertical-align: top;
  position: relative;
}

.content-image {
  margin-top: 10px;
  width: 100%;
  height: auto;
  border-radius: 17.5px;
}




.Modal {
  position: absolute;
  width: 50%;
  margin-left: 25%;
  background-color: papayawhip;
  top: 50%;
  transform: translateY(-50%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.height{

  height: 100vh;
}

.form{

  position: relative;
}


.form span{

  position: absolute;
  right: 17px;
  top: 13px;
  padding: 2px;
  border-left: 1px solid #d1d5db;

}

.left-pan{
  padding-left: 7px;
}

.left-pan i{

  padding-left: 10px;
}

.form-input{

  height: 55px;
  text-indent: 33px;
  border-radius: 10px;
}

.form-input:focus{

  box-shadow: none;
  border:none;
}

.aesthetic-button {
  border-radius: 25px;
  background-color: #c2ba50;
  color: white;
  border: none;
  padding: 5px 10px 5px 10px;
  margin-right: 5px;
  text-decoration: none;
}

.label-button {
  border: none;
  margin-right: 10px;
  background-color: #efefef;
  padding: 0px 8px 0px 8px;
}
.gold-text {
  color: #c2ba50;
}

.blue-text {
  color: #396de0;
}

.gold-btn {
  background-color: #c2ba50;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight : bold;
}

.gold-inverse-btn {
  background-color: white;
  border: 2px solid #c2ba50;
  color: #c2ba50;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight : bold;
}

.blue-btn {
  background-color: #396de0;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight : bold;
}

:focus {
  outline: none !important;
}

body, html {
  font-family: 'forma-djr-display', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.font-medium {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.pointer:hover {
  cursor: pointer;
}