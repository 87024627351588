.edit-aesthetic-img {
    width: 30px;
    height: auto;
    background-color: #396de0;
    border-radius: 50%;
    padding: 3px;
}

.aesthetic-modal {
    width: 400px;
    height: 200px;
    margin: auto;
    margin-top: 30vh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
}

.aesthetic-edit-container {
    margin-left: 20px;
    padding-top: 10px;
    margin-right: 20px;
}

.save-aesthetic-btn {
    float: right;
    margin-top: 10px;
}
