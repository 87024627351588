.reported-content-container {
    height: 40vh;
    margin-top: 25px;
}

.reported-img {
    height: 40vh;
    width: auto;
    border-radius: 15px;
    margin-left: 25px;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
}

.content-info-div {
    display: inline-block;
}

.inline-div * {
    display: inline-block;
}

.user-img-preview {
    height: 100px;
    width: 100px;
    border-radius: 100px;
}

.report-button {
    display: block;
    margin-bottom: 20px;
    width: 150px;
}

.username-text {
    margin-left: 10px;
}

.deny-buttons-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.deny-buttons-div * {
    margin-left: 15px;
    margin-right: 15px;
    width: 100px;
}