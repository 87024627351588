.homepage-img-div {
    width: 25vw;
    height: calc((100vh - 62px) / 3);
    display: inline-block;
}

.homepage-img-div img {
    width: 25vw;
    height: calc((100vh - 62px) / 3);
    object-fit: cover;
    vertical-align: baseline;
}

.homepage-row-div {
    width: 100%;
    height: calc((100vh - 62px) / 3);
    position: relative;
}

.content-preview-div {
    width: 20%;
    height: auto;
    display: inline-block;
}

.homepage-modal {
    width: 90vw;
    height: 90vh;
    margin: auto;
    margin-top: 5vh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    overflow: scroll;
}

.homepage-div {
    overflow: hidden;
}

.homepage-search-input {
    position: absolute;
    top: 33.3%;
    left: 25%;
    width: 50%;
    height: 33.3%;
    border-radius: 50px;
    background-color: transparent;
    border: 3px solid white;
    padding-left: 30px;
    color: white;
    font-size: xx-large;
}

.homepage-search-input::placeholder {
    color: white;
    font-size: xx-large;
}

.homepage-save-btn {
    position: absolute;
    left: 45%;
    bottom: 0;
    width: 10%;
}
