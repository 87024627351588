.text-link {
    display: inline-block;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 2px #c2ba50 solid;
    font-weight: bold;
}

.final-text-link {
    display: inline-block;
    font-weight: bold;
}

.links {
    position: relative;
    transform: translateY(20%);
}

.search-bar input {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    width: 90%;
    display: inline-block;
    border-style: solid solid solid none;
    z-index: -1;
    margin-left: -20px;
    padding-left: 35px;
    border-color: #396de0;
    background-color: #fcf5e8;
    border-width: 2px;
}

.search-bar input::placeholder {
    color: #396de0;
    font-weight: bold;
}

.search-bar input:focus {
    box-shadow: none;
}

.search-bar div a span{
    border-radius: 25px;
}

.dupe-logo {
    width: 10%;
    display: inline-block;
    z-index: 1;
    margin-left: 10px;
    position: relative;
    z-index: 99;
}

.dupe-logo a span {
    background-color: #396de0;
    color: white;
    border: 2px solid #396de0;

}

.thumbnail-image {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
}

.down-arrow {
    height: 10px;
    width: auto;
}

.profile {
    float: right;
    margin-right: 15px;
}

.dupe-navbar {
    background-color: #fcf5e8;
}

.white-background {
    background-color: white;
}

.white-background input {
    background-color: white;
}

.hamburger {
    width: 50px;
    height: auto;
}

.sidenav {
    width: 350px;
    transition: width 0.3s ease-in-out;
    height: 100vh;
    background-color: #396de0;
    position: fixed;
    top: 0;
    right: 0;
}

.sidenavClosed {
    composes: sidenav;
    transition: width 0.3s ease-in-out;
    display: none;
}

.side-x {
    margin-left: 15px;
    margin-top: 15px;
    color: lightgrey;
}

.menu-options {
    width: 100%;
    text-align: center;
    margin-top: 10vh;
}

.menu-options h3 {
    margin-top: 15px;
    color: white;
}

.menu-options h3:hover {
    text-decoration: underline white;
    text-underline-offset: 5px;
    color: #c2ba50;
}

.login-btn {
    border-radius: 25px;
    background-color: #396de0;
    border: 1px solid #396de0;
    color: white;
    margin-right: 10px;
    width: 70px;
}

.signup-btn {
    border-radius: 25px;
    background-color: white;
    border: 1px solid #396de0;
    color: #396de0;
    margin-right: 10px;
}
