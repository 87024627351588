.login-img {
    width: 100%;
    height: 101vh;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    margin-top: -.5vh;
}

.google-logo {
    width: 50%;
}

.login-div {
    text-align: center;
}

.login-page-btn {
    width: 100%;
    border-radius: 15px;
}

.password-div {
    position: relative;
}

.password-eye-img {
    width: 40px;
    height: auto;
    background-color: transparent;
    position: absolute;
    right: 15px;
    top: calc(50% - 20px);
}

.password-eye-img:hover {
    cursor: pointer;
}
