.content-container {
    position: relative;
}

.content-module-container {
    width: 100%;
    padding-left: 1.25%;
}

.image-column:first-child {
    display: inline-block;
    width: 19%;
    margin-left: 1.25%;
    vertical-align: top;
    position: relative;
}

.image-column {
    display: inline-block;
    width: 19%;
    margin-left: .625%;
    vertical-align: top;
    position: relative;
}

.content-image {
    margin-top: 10px;
    width: 100%;
    height: auto;
    border-radius: 17.5px;
}

.collection-modal {
    width: 350px;
    height: 50vh;
    background-color: white;
    border: none;
    border-radius: 15px;
}

.collection-box {
    position: relative;
    width: 100%;
    height: 50vh;
}

.create-collection-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 9vh;
    border-top: 1px black solid;
    padding-top: .5vh;
    padding-bottom: .5vh;
    display: flex;
    align-items: center;
}

.collection-search {
    padding-top: 10px;
    padding-bottom: 10px;
}

.collection-search input {
    margin-left: 5%;
    width: 90%;
    border-radius: 20px;
    border: none;
    background: #EFEFEF;
    height: 30px;
    padding-left: 10px;
}

.collection-plus {
    display: inline-block;
    height: 6.5vh;
    width: 6.5vh;
    background-color: #c2ba50;
    border-radius: 15px;
    margin-left: 5%;
    position: relative;
}

.collection-plus img {
    width: 15px;
    height: 15px;
    left: calc(50% - 7.5px);
    top: calc(50% - 7.5px);
    position: absolute;
}

.create-collection-footer h3 {
    display: inline-block;
    margin-left: 2.5%;
    margin-bottom: 0;
}

.back-arrow {
    display: inline-block;
    height: 6.5vh;
    width: 6.5vh;
    background-color: transparent;
    border-radius: 15px;
    margin-left: 5%;
}

.create-button {
    position: absolute;
    right: 0;
    margin-right: 15px;
    border-radius: 15px;
    margin-bottom: 5px;
    display: inline-block;
}

.image-preview {
    height: 7.5vh;
    width: 7.5vh;
    object-fit: cover;
    display: inline-block;
    border-radius: 5px;
}

.new-collection-header {
    padding-top: 10px;
    margin-left: 10%;
}

.new-collection-header h3 {
    display: inline-block;
    margin-left: 10px;
}

.collection-input {
    margin-left: 10%;
    width: 80%;
    margin-top: 10px;
}

.collection-input input {
    background-color: #EFEFEF;
    border: none;
    border-radius: 5px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
}

.collection-input input textarea {
    color: #737373;
}

.collection-input textarea {
    background-color: #EFEFEF;
    border: none;
    border-radius: 5px;
    width: 100%;
    padding-left: 10px;
    height: 22vh;
}

.collection-preview {
    width: 100%;
    height: 10vh;
    position: relative;

}

.collection-preview img {
    object-fit: cover;
    width: 10vh;
    height: 10vh;
    display: inline-block;
    border-radius: 10px;
    margin-left: 10px;
}

.empty-collection-div {
    width: 10vh;
    height: 10vh;
    display: inline-block;
    background-color: #EFEFEF;
    border-radius: 10px;
    margin-left: 10px;
}

.empty-collection-div img {
    object-fit: cover;
    width: 7.5vh;
    height: 7.5vh;
    display: inline-block;
    border-radius: 10px;
    margin-left: 1.25vh;
    margin-top: 1.25vh;
}

.collection-preview h3 {
    display: inline-block;
    margin-left: 10px;
}

.collection-preview button {
    float: right;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 10px;
    border-radius: 20px;
    visibility: hidden;
    background-color: #c2ba50;
    color: white;
    border: none;
    height: 5vh;
    width: 60px;
    font-weight : bold ;
}

.collection-preview:hover button {
    visibility: visible;
}

.content-modal {
    width: 50%;
    margin-left: 25%;
    max-height: 90vh;
    margin-top: 10vh;
    background-color: white;
    border: none;
    border-radius: 15px;
}

.content-summary {
    display: flex;
    margin-left: 2.5vh;
}

.selected-content {
    display: inline-block;
    flex: 1;
    margin-right: 2.5vh;
}

.selected-content img {
    width: 100%;
    height: auto;
    border-radius: 25px;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
}

.content-info {
    display: inline-block;
    flex: 1;
    position: relative;
    margin-top: 30px;
}

.aesthetics {
    margin-top: 50px;
}


.labels {
    margin-top: 45px;
}

.labels fragment a {
    border: none;
    margin-right: 10px;
    background-color: #efefef;
    padding: 0px 8px 0px 8px;
    margin-bottom: 10px;
}

.labels fragment a:hover {
    background-color: lightgrey;
}

.download-btn {
    position: absolute;
    right:    10px;
    bottom:   2.5vh;
    border-radius: 25px;
    background-color: #c2ba50;
    color: white;
    border: none;
    padding: 10px;
}
.user-div {
    border-bottom: 1px black solid;
    width: 80%;
    padding-bottom: 20px;
    display: flex;
    flex-direction:row;
}

.user-div:hover {
    cursor: pointer;
}

.user-div-preview {
    width: 100%;
}

.user-div-preview:hover {
    cursor: pointer;
}


.user-div img {
    height: 5vh;
    width: 5vh;
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    border-radius: 50%;
    object-fit: cover;
}

.user-div h5 {
    display: inline-block;
    margin-left: 10px;
}

.content-overlay-div {
    opacity: 0;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #182f60;
    border-radius: 17.5px;
    overflow: hidden;
}

.content-overlay-div:hover {
    opacity: 1;
    background-color: rgba(24, 47, 96, .7);
}

.plus-icon {
    position: absolute;
    width: 20px;
    height: auto;
    top: 15px;
    right: 40px;
    background-color: transparent;
}

.like-icon {
    position: absolute;
    width: 20px;
    height: auto;
    top: 15px;
    right: 15px;
    background-color: transparent;
}

.plus-icon:hover {
    cursor: pointer;
}

.like-icon:hover {
    cursor: pointer;
}

.download-icon {
    position: absolute;
    width: 20px;
    height: auto;
    top: 15px;
    left: 15px;
    background-color: transparent;
}

.download-icon:hover {
    cursor: pointer;
}

.edit-icon {
    position: absolute;
    width: 30px;
    height: auto;
    top: 10px;
    left: 10px;
    background-color: transparent;
}

.edit-icon:hover {
    cursor: pointer;
}

.content-profile-image {
    width: 3vw;
    height: 3vw;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.content-profile-name {
    position: absolute;
    bottom: 25px;
    left: calc(3vw + 20px);
    color: white;
    margin-bottom: 0;
    font-size: 20px;
}

.content-profile-username {
    position: absolute;
    bottom: 12px;
    left: calc(3vw + 20px);
    color: white;
    margin-bottom: 0;
    font-size: 12px;
}

.likes-img {
    width: 30px;
    height: 30px;
    display: inline-block;
}

.stats-div {

}

.deny-btn {
    position: absolute;
    height: 40px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    background-color: transparent;
}

.approve-btn {
    position: absolute;
    height: 40px;
    bottom: 60px;
    left: 10px;
    right: 10px;
    background-color: transparent;
}

.star-icon {
    position: relative;
    display: inline-block;
    height: auto;
    width: 15%;
    margin-left: 10px;
    margin-top: 10px;
    background-color: transparent;
}

.rated ~ img {
    opacity: 0;
}

.rated {
    content: green;
}


.star-icon:hover ~ img {
    opacity: 0;
}

.star-icon:hover {
    opacity: 1;
}

.star-icon:has(~ .star-icon:hover) {
    opacity: 1;
}

