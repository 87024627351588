.user-profile {
    display: inline-block;
    width: 10vw;
    height: 10vw;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
}

.user-preview-div {
    margin-top: 20px;
    margin-left: 2.5%;
    width: 97.5%;
}
