.tag-preview-img {
    width: auto;
    max-width: 50%;
    height: 30vh;
    border-radius: 15px;
    display: inline-block;
    vertical-align: top;
}

.tag-content-div {
    width: 90%;
    margin-left: 5%;
    margin-top: 15px;
    background-color: #EFEFEF;
    border-radius: 15px;
    position: relative;
}

.tags-container {
    display: inline-block;
    margin-left: 25px;
    width: 50%;
}

.label-p {
    display: inline-block;
    margin-right: 10px;
    background-color: white;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.add-tag-img {
    width: 40px;
    height: auto;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    padding: 5px;
    background-color: white;
}

.add-tag-modal {
    width: 50vw;
    max-height: 70vh;
    margin: auto;
    margin-top: 20vh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    overflow: hidden;
}

.confirm-delete-modal {
    width: 30vw;
    max-height: 70vh;
    margin: auto;
    margin-top: 20vh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    padding: 15px;
}

.new-tag-div {
    display: inline-block;
    max-width: 50%;
}

.new-tag-preview-img {
    width: auto;
    max-width: 50%;
    height: 50vh;
    border-radius: 15px;
    display: inline-block;
    vertical-align: top;
}

.new-tag-input {
    margin-left: 25px;
    border: none;
    border-bottom: 2px solid #396de0;
    margin-top: 25px;
    margin-bottom: 25px;
}

.new-p {
    border: 1px solid #396de0;
}

.submit-tag-btn {
    display: block;
    width: 100%;
    margin-top: 10px;
}

.confirm-delete-tag-btn {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}

.content-video {
    margin-top: 10px;
    width: auto;
    height: 35vh;
    border-radius: 17.5px;
}